body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

header {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 20px 30px;
  font-size: 20px;
  background-color: rgba(49,118,84,1);
  color: white;
  
}

h1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0;
  

}

.title {
  cursor: pointer;
  margin-bottom:0px;
  line-height:1;
  font-size: 18px;
 
}

.title:hover {
  text-decoration: underline;
}

nav {
  display: flex;
  gap: 20px;
  height: 45px;
  position: relative;
  font-size: 15px;
}


@media (max-width: 1370px) {  
  header nav {
    margin-top: -10px;
  }
  
}

@media (max-width: 930px) {  
  header nav {
    display: none;
  }
}

@media (min-width: 930px) {  
  header nav {
    display: flex;
    flex-wrap: wrap;
  }
}

.dropdown-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}

nav button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em; 
}

.nav-footer button {
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  
}

.nav-footer button:hover {
  text-decoration: underline;
}


nav button:hover {
  text-decoration: underline;
}


.dropdown-about {
  position: absolute;
  top:40px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
}

.dropdown-aboutCN {
  position: absolute;
  top:40px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
}

@media (max-width: 1533px){
  .dropdown-about{
    top:40px;
  }
  }
  

.dropdown-about button,
.dropdown-aboutCN button{
  background-color: inherit;  
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  word-break: keep-all;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
}


.dropdown-about button:hover
.dropdown-about button:hover{
  background-color: #f1f1f1;
}


.dropdown-interview,
.dropdown-interviewCN {
  position: absolute;
  top:40px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

}

@media (max-width: 1533px){
  .dropdown-interview{
    top:40px;
  }  
}
  
.dropdown-interview button, 
.dropdown-interviewCN button{
  background-color: inherit;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
}

.dropdown-interview button:hover,
.dropdown-interviewCN button:hover{
  background-color: #f1f1f1;
}

header .about-button::after,
header .interview-button::after {
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-left: 5px;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.title-footer{
  cursor: pointer;
  font-size: 17px;
}

.title-footer:hover {
  text-decoration: underline;
}
