.ProjectHeading-Container{
  font-size: 19px;
}

.ProjectPreliminaryConclusions{
  background-color: rgba(49,118,84,1);
}

.ProjectPreliminaryConclusions-Container{
  font-size: 18px;
  color: white;
}

.ProjectCharts {
  width: 95vw;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  perspective: 1000px;
  padding-top: 40px;
  margin: auto;
}

.ProjectChart {
  width: 650px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 1s ease;
  position: relative;
  transform: rotateY(10deg) scale(.4);
  opacity: 0.5;
  margin-right: -35%;
  margin-left: -35%;
  z-index: 0;
}

@media (min-width: 1700px) {
  .ProjectCharts {
    width: 95vw;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    perspective: 1000px;
    padding-top: 40px;
    margin: auto;
  }
   
  .ProjectChart {
    width: 750px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 1s ease;
    position: relative;
    transform: rotateY(10deg) scale(.4);
    opacity: 0.5;
    margin-right: -32%;
    margin-left: -32%;
    z-index: 0;
  }
}


@media (min-width: 2250px) {
  .ProjectCharts {
    width: 95vw;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    perspective: 1000px;
    padding-top: 40px;
    margin: auto;
  }
  
  .ProjectChart {
    width: 850px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 1s ease;
    position: relative;
    transform: rotateY(10deg) scale(.4);
    opacity: 0.5;
    margin-right: -27%;
    margin-left: -27%;
    z-index: 0;
  }
}

.ProjectChart.active {
  transform: rotateY(0) scale(1);
  opacity: 1;
  position: relative;
  z-index: 1;
}

.ProjectButtonContainerButton {
  background-color: rgba(49,118,84,1);
  color: white;
  border: none;
  padding: 10px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.ProjectButtonContainerButton:hover {
  background-color: #0056b3;
}

.ProjectContent1{
  background-color: rgba(49,118,84,1);
}

.ProjectContent1-Container{
  background-color: rgba(49,118,84,1);
  font-size: 19px;
}
  