.Team{
 
}

.Team-intro{
    font-size: 50px ;
}

.Team-intro-container{
    
    
}

.Team-section1{
    background-color: rgba(49,118,84,1);
}


.Team-section1-title {

}


.Team-section2{
    
}

.Team-section2-title {
  
    
}

.Team-section3{
    background-color: rgba(49,118,84,1);
 
}


.Team-section3-title {
}

.Team-section4{
}

.Team-section4-title {
}

.Team-section5{
    background-color: rgba(49,118,84,1);
    
}

.Team-section5-title {

}


