.dot-chart {
  width: 100%;
}

.dot {
  width: 13px;
  height: 14px;
  border-radius: 50%;
  background-color: #000;
  margin: 2px;
  opacity: 0;
  transform: translateY(-50px); 
  animation: dotDrop 1s ease-in-out forwards; 
}

.dot-chart-number {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.chart-only{
  display: flex;
  flex-wrap: wrap;
  transform: rotate(180deg);
  transform-origin: center;
  justify-content: flex-end;
}

@keyframes dotDrop {
  0% { transform: translateY(-50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}



